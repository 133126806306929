import styled from "styled-components";

export const FadeUpContainer = styled.div<{ $bckgroundColor?: string, $noBefore?: boolean }>`
    animation-duration: 500ms;
    animation-fill-mode: both;
    opacity: 0;
    opacity: 0;
    animation-name: fadeInUp;
    animation-timing-function: ease-out;

    &::before {
        height: 30px;
        width: 100%;
        background: ${({ $bckgroundColor }) => $bckgroundColor || "white"};
        top: 0;
        left: 0;
        content: "";
        display: ${({ $noBefore }) => $noBefore ? "none" : "block"};
        z-index: -1;
        position: relative;
    }

    /* Animation */
    @keyframes fadeInUp {
        from {
            transform: translate3d(0, 30px, 0)
        }

        to {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }
`;