import styled from "styled-components";
import { media } from "../../theme/theme";
import { MarketingSpacing } from "../ReusableStyledComponents/MarketingSpacing";
import { SmallText } from "../Typography";

const SM_BREAKPOINT = 480;
const BREAKPOINT = 1110;

export const MarketingFooterWrapper = styled.div<{ backgroundColor?: string }>`
    width: 100%;
    background:  ${({ theme, backgroundColor }) => backgroundColor ? backgroundColor : theme.marketing.colors.blackPearl30};
    padding: 63px 0;
`;

export const MarketingFooterContent = styled.div`
    width: 100%;
    max-width: 1256px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    ${MarketingSpacing}

    ${media.custom(BREAKPOINT)} {
        flex-wrap: nowrap;
    }

    a {
        text-decoration: none;
    }

    > div {
        &:first-child {
            position: absolute;
            bottom: 0;
            left: 50%;
            align-items: center;
            align-items: initial;

            ${media.custom(SM_BREAKPOINT)} {
                order: 1;
                width: 100%;
                align-items: center;
                position: initial;
                bottom: initial;
            }

            ${media.custom(BREAKPOINT)} {
                order: initial;
                width: auto;
                align-items: initial;
            }
        }

        &:last-child {
            order: -1;
            width: 100%;
            text-align: center;
            margin-bottom: 50px;

            ${media.custom(BREAKPOINT)} {
                order: initial;
                width: auto;
                text-align: left;
                margin-bottom: 0;
            }
        }
    }
`;

export const ColumnsLinkWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    max-width: 331px;

    ${media.custom(SM_BREAKPOINT)} {
        max-width: 600px;
        margin: 0 auto 48px;
        justify-content: space-between;
    }

    ${media.custom(BREAKPOINT)} {
        flex-wrap: nowrap;
        width: 100%;
        max-width: 566px;
        margin: initial;
    }

    > div {
        width: 50%;

        &:nth-child(2) {
            ${media.custom(BREAKPOINT)} {
                margin-left: 10px;
            }
        }

        &:not(:last-child) {
            margin-bottom: 48px;

            ${media.custom(SM_BREAKPOINT)} {
                margin-bottom: 0;
            }
        }

        ${media.custom(SM_BREAKPOINT)} {
            width: auto;
        }

        ${media.custom(BREAKPOINT)} {
            justify-content: space-between;
            margin-bottom: 0;

            &:not(:last-child) {
                margin-right: 17px;
            }
        }
    }
`;

export const ImprintText = styled(SmallText)`
    color:  ${({ theme }) => theme ? theme.marketing.colors.dodgerBlue80 : null};

    br {
        display: block;

        ${media.custom(SM_BREAKPOINT)} {
            display: none;
        }

        ${media.custom(BREAKPOINT)} {
            display: block;
        }
    }

    span {
        ${media.custom(SM_BREAKPOINT)} {
           margin-right: 8px;
        }

        ${media.custom(BREAKPOINT)} {
            margin-right: 0;
        }
    }
`;

export const ImprintLink = styled.div`
    color:  ${({ theme }) => theme ? theme.marketing.colors.dodgerBlue80 : null};
    display: inline-block;
    
    &:hover{
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const VerticalSpacingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const FooterMainLink = styled(ImprintLink)`
    color: ${({ theme }) => theme ? theme.marketing.colors.blackPearl70 : null};
`;

export const FooterLinkColumn = styled.ul`
    li {
        margin-bottom: 10px;
    }
`;