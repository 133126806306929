import Head from 'next/head';
import { CookieYesBanner } from '../CookieYesBanner';

type OGTagsProps = {
    title?: string,
    description?: string,
    forceNoRobot?: boolean,
    canonicalUrl?: string
}

export const MarketingOGTags = ({
    description = "Lean back while Authory creates a stunning portfolio for you, plus it automatically finds and backs up all the content you've ever created.",
    title = "Authory – Your Self-Updating Portfolio and Content Archive",
    forceNoRobot = false,
    canonicalUrl,
}: OGTagsProps) => {

    const imagePath = `${process.env.NEXT_PUBLIC_FRONTEND_URL}/authory_static_files/images/authory_og.png?cachebust=wdadwad`;

    return (
        <>
            <Head>
                <title>{title || "Authory"}</title>
                <meta name="description" content={description} />
                <meta name="og:description" content={description} />
                <meta name="twitter:description" content={description} />
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
                {(process.env.NEXT_PUBLIC_SENTRY_ENV !== 'production' || forceNoRobot) && <meta name="robots" content="noindex" />}
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
                <meta name="twitter:site" content="@Authory" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:image" content={imagePath} />
                <meta property="og:image" content={imagePath} />
                {
                    !!canonicalUrl && <>
                        <link rel="canonical" href={canonicalUrl} />
                        <meta property="og:url" content={canonicalUrl} />
                    </>
                }
            </Head>
            <CookieYesBanner />
        </>
    );
};
