import { css } from "styled-components";
import { media } from "../../theme/theme";

export const MarketingSpacing = css`
    padding: 0 16px;

    ${media.phone} {
        padding: 0 40px;
    }
`;
